import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25')
];

export const server_loads = [2,3];

export const dictionary = {
		"/": [4],
		"/about": [5,[2]],
		"/about/community": [6,[2]],
		"/about/guides": [7,[2]],
		"/about/humans": [8,[2]],
		"/about/insides": [9,[2]],
		"/about/platform/context": [10,[2]],
		"/about/privacy": [11,[2]],
		"/about/terms": [12,[2]],
		"/sign-in": [13],
		"/tttest/sentry-example": [25],
		"/tt": [~14,[3]],
		"/tt/account": [~15,[3]],
		"/tt/admin/odin": [~16,[3]],
		"/tt/conversations": [~17,[3]],
		"/tt/conversations/create": [~19,[3]],
		"/tt/conversations/[id]": [~18,[3]],
		"/tt/library": [~20,[3]],
		"/tt/library/documents": [~21,[3]],
		"/tt/library/documents/import": [~23,[3]],
		"/tt/library/documents/new": [~24,[3]],
		"/tt/library/documents/[id]": [~22,[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';